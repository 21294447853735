.wrapper {
  display: flex;
  padding: 0 48px 53px !important;
  background: var(--gray200);
  color: var(--dark100);
  justify-content: center;
}

.title {
  font-family: var(--urbanist);
  letter-spacing: 0.03em;
}
.title {
  font-size: 32px !important;
  line-height: 38px !important;
  font-weight: 900;
  letter-spacing: 0.03em;
  text-transform: none;
}
.text {
  color: var(--dark60);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: normal;

  &:not(:last-of-type) {
    padding-bottom: 1ch;
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 18px 0 0 0;
  max-width: 354px;
  min-width: 354px;
  height: fit-content;

  :global(.image-cont) {
    padding: 0;
  }
}

.btn {
  background: var(--dark100); // TODO to button
  margin-top: 32px;
  width: 100%;

  // TODO button component
  & a span {
    font-size: 12px !important;
  }
}

.icon {
  width: 48px;
  height: 48px;
}

.titleMobile {
  display: none;
}

.legendBtn {
  margin: 0 auto;
  margin-top: 64px;
  margin-bottom: 128px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  max-width: 100%;
  padding-top: 24px;
  gap: 32px;
}

@media (min-width: 901px) {
  .content {
    width: 100%;
  }
}

@media (max-height: 767px) {
  .content {
    height: calc(100vh - var(--headerHeight) - 72px);
  }
}

@media (max-height: 570px), (max-width: 900px) {
  .content,
  .wrapper {
    min-height: auto;
    max-width: unset;
    width: 100%;
    padding: 24px 24px 0 !important;
    flex-direction: column-reverse;
    justify-content: flex-end;
    gap: 4px;
    flex: 1 1;
    align-items: unset;
  }

  .content {
    min-height: auto;
    height: fit-content;
    padding: 0 0 24px 0 !important;
  }

  .col {
    min-width: 100%;
    padding: 0;
    gap: 12px;
  }

  .btn {
    margin-top: 0;
  }

  .legendBtn {
    margin-bottom: 64px;
  }
}

@media (max-width: 900px) and (min-width: 601px) {
  .btn {
    width: 161px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 600px) {
  .wrapper {
    padding: 16px 16px 0 !important;
    gap: 0;
    h1 {
      font-size: 32px !important;
      line-height: 120% !important;
    }

    p {
      font-size: 16px;
      line-height: 24px;
    }

    .icon {
      display: none;
    }
  }

  .title {
    display: none;
  }

  h1.titleMobile {
    display: block;
    margin-bottom: 16px;
    font-size: 28px !important;
    letter-spacing: normal;
  }

  .col {
    padding-top: 24px;
    gap: 0;
    .title {
      font-size: 28px;
      line-height: 34px;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .btn {
    margin-top: 40px;
  }

  .legendBtn {
    margin-top: 40px;
  }
}
